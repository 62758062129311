import React from "react";
import { graphql } from "gatsby";
import PackageTemplate1 from "../components/v1/templates/packageTemplate";
import PackageTemplate2 from "../components/v2/templates/packageTemplate";

const PackageTemplate = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <PackageTemplate1 {...props} />;
  } else {
    return <PackageTemplate2 {...props} />;
  }
};

export const pageQuery = graphql`
  query($faq_slug: String!) {
    faqs: allMarkdownRemark(
      filter: { fields: { category: { eq: "faqs" } } }
      sort: { order: ASC, fields: [frontmatter___order] }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            question
            answer
          }
        }
      }
    }

    testimonials: allMarkdownRemark(
      filter: {
        fields: { category: { eq: "testimonials" } }
        frontmatter: { packages_included: { in: [$faq_slug] } }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            title
            author
            testimonial
            articleRoute
            imageSuffix
          }
        }
      }
    }

    metadata: site {
      siteMetadata {
        cloudfront_base
      }
    }
  }
`;

export default PackageTemplate;
