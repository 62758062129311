import React, { useState } from "react";
import styles from "./PortfolioSection.module.scss";
import cx from "classnames";
import RightArrowButton from "../../../commons/curve-arrow-button/RightArrowButton";
import { getPortfolioLinkFromSlug } from "../../../../utils/link.helper";
import ImagePreview from "./image-preview";
import { useMediaQuery } from "react-responsive";
import { smallScreenQuery } from "../../../../utils/utils";
import { leftArrowComponent, rightArrowComponent } from "./shared";
import { Video } from "../../../../models/video";
import VideoContainer from "./video-container";

interface Props {
  portfolioSlug: string;
  currentPortfolioImages: {
    url: string;
    caption: string;
    link: string;
  }[];
  currentPortfolioVideos: Video[];
}

const Portfolios: React.FC<Props> = (props: Props) => {
  const [showPreview, setShowPreview] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const mobile = useMediaQuery({ query: smallScreenQuery });

  const {
    portfolioSlug,
    currentPortfolioImages,
    currentPortfolioVideos,
  } = props;

  const getTotalMediaLength = () => {
    if (currentPortfolioVideos) {
      return currentPortfolioVideos.length + currentPortfolioImages.length;
    } else {
      return currentPortfolioImages.length;
    }
  };

  const mediaGoToPreviousSlide = () => {
    const totalLength = getTotalMediaLength();

    if (currentMediaIndex === 0) {
      setCurrentMediaIndex(totalLength - 1);
    } else {
      setCurrentMediaIndex(currentMediaIndex - 1);
    }
  };

  const mediaGoToNextSlide = () => {
    const totalLength = getTotalMediaLength();

    if (currentMediaIndex === totalLength - 1) {
      setCurrentMediaIndex(0);
    } else {
      setCurrentMediaIndex(currentMediaIndex + 1);
    }
  };

  const leftArrow = (className?: string) =>
    leftArrowComponent(mediaGoToPreviousSlide, className);

  const rightArrow = (className?: string) =>
    rightArrowComponent(mediaGoToNextSlide, className);

  const handleImagePreview = () => {
    if (mobile) {
      setShowPreview(true);
    }
  };

  const showMediaHandler = () => {
    if (currentPortfolioVideos) {
      if (currentMediaIndex < currentPortfolioVideos.length) {
        return (
          <VideoContainer
            title={currentPortfolioVideos[currentMediaIndex].title}
            src={currentPortfolioVideos[currentMediaIndex].src}
          />
        );
      } else {
        return (
          <figure onClick={handleImagePreview}>
            <img
              src={
                currentPortfolioImages[
                  currentMediaIndex - currentPortfolioVideos.length
                ].url
              }
              alt="Portfolio Package"
            />
          </figure>
        );
      }
    } else {
      return (
        <figure onClick={handleImagePreview}>
          <img
            src={currentPortfolioImages[currentMediaIndex].url}
            alt="Portfolio Package"
          />
        </figure>
      );
    }
  };

  return (
    <section className={cx("column", styles.portfolio)}>
      <section className={styles.portfolio__heading}>
        <h3>Portfolio</h3>
        <RightArrowButton
          text="View More"
          className={cx("open", styles.portfolio__btn)}
          route={getPortfolioLinkFromSlug(portfolioSlug)}
        />
      </section>
      <div className={styles.image}>
        {leftArrow(styles.image__desktop)}
        {showMediaHandler()}
        {rightArrow(styles.image__desktop)}
      </div>
      <div className={styles.switch}>
        {leftArrow()}
        {rightArrow()}
      </div>
      {showPreview && (
        <ImagePreview
          currentPortfolioImages={currentPortfolioImages.map(el => el.url)}
          selectedIndex={
            currentPortfolioVideos
              ? currentMediaIndex - currentPortfolioVideos.length
              : currentMediaIndex
          }
          closeFunc={() => setShowPreview(false)}
        />
      )}
    </section>
  );
};

export default Portfolios;
