import React from "react";
import styles from "./ChooseSection.module.scss";
import cx from "classnames";
import PackageCard from "./packages-card/PackagesCard";
import Slider from "infinite-react-carousel";
import { Slugs } from "../../../../enums/slugs.enum";

interface Props {
  title: string;
  subtitle: string;
  options: {
    title: string;
    price: string;
    description: string[];
  }[];
  selectPackage: Function;
  locationSlug: string;
}

const carouselSettings = {
  arrows: false,
  arrowsBlock: false,
  dots: true,
  adaptiveHeight: true,
};

const ChoosePackage: React.FC<Props> = (props: Props) => {
  const packagesCard = (
    index: number,
    title: string,
    price: string,
    description: string[]
  ) => {
    return (
      <PackageCard
        key={index}
        name={title}
        price={price}
        desc={description}
        choose={() => props.selectPackage(title)}
      />
    );
  };

  return (
    <section className={styles.backgroundContainer}>
      <div className={cx("column", styles.choosePackage)}>
        <h3>Choose your package</h3>
        {props.subtitle && (
          <h6 className={styles.choosePackage__subtitle}>{props.subtitle}</h6>
        )}
        <p className={cx(styles.choosePackage__desc, "open")}>{props.title}</p>
        <section className={styles.choosePackage__desktop}>
          {props.options.map(({ title, price, description }, index) => {
            return packagesCard(index, title, price, description);
          })}
        </section>
        <section className={styles.choosePackage__mobile}>
          <Slider {...carouselSettings}>
            {props.options.map(({ title, price, description }, index) => {
              return packagesCard(index, title, price, description);
            })}
          </Slider>
        </section>
        {props.locationSlug !== Slugs.LOCATION_SF_BAY_AREA && (
          <p className={cx(styles.choosePackage__conditions, "open")}>
            &#42;&#42;&nbsp;Hawaii state general excise tax (4.712% approx.) & credit
            card fee (3.5%) are not included in Hawaii packages.
          </p>
        )}
      </div>
    </section>
  );
};

export default ChoosePackage;
