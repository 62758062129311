import React, { useState } from "react";

import Overlay from "../../../overlay/Overlay";
import styles from "./ImagePreview.module.scss";
import CloseIcon from "../../../../../images/icons/cross-icon.inline.svg";
import { IconSizes } from "../../../../../enums/icon-sizes";
import cx from "classnames";
import SVGContainer from "../../../../commons/svg-container/SVGContainer";
import { StringUtil } from "../../../../../utils/string.util";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { leftArrowComponent, rightArrowComponent } from "../shared";

interface Props {
  currentPortfolioImages: string[];
  selectedIndex: number;
  closeFunc: () => void;
}

const ImagePreview: React.FC<Props> = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);

  const { currentPortfolioImages } = props;

  const goToPreviousSlide = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(currentPortfolioImages.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const goToNextSlide = () => {
    if (selectedIndex === currentPortfolioImages.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const imageFileName = StringUtil.getFileNameFromURL(
    currentPortfolioImages[selectedIndex]
  );

  return (
    <div className={styles.imagePreview}>
      <Overlay className={styles.overlay}>
        <div className={cx("column", styles.container)}>
          <header>
            <SVGContainer iconSize={IconSizes.IC__24} onClick={props.closeFunc}>
              <CloseIcon />
            </SVGContainer>
          </header>
          <section className={cx("column", styles.content)}>
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={currentPortfolioImages[selectedIndex]}
                  alt={imageFileName}
                />
              </TransformComponent>
            </TransformWrapper>
          </section>
          <div className={styles.switch}>
            {leftArrowComponent(goToPreviousSlide)}
            {rightArrowComponent(goToNextSlide)}
          </div>
        </div>
      </Overlay>
    </div>
  );
};

export default ImagePreview;
