import React from "react";
import styles from "./CTA.module.scss";

interface Props {
  title?: string;
  lines: string[];
  showExpertiseIcon?: boolean;
}

const CTA: React.FC<Props> = (props: Props) => {
  const { title, lines, showExpertiseIcon } = props;
  return (
    <section className={styles.container}>
      <div className={styles.main}>
        <h3 className={styles.title}>{title || "A Few Good Clicks"}</h3>
        {lines.map((l, index) => (
          <p className={styles.line} key={index}>
            {l}
          </p>
        ))}
      </div>
      {showExpertiseIcon && (
        <div className={styles.expertise}>
          <a
            href="https://www.expertise.com/ca/redwood-city/wedding-photography"
            style={{ display: "inline-block", border: "0" }}
          >
            <img
              style={{ width: "200px", display: "block" }}
              width="200"
              height="160"
              src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/ca_redwood-city_wedding-photography_2022_transparent.svg"
              alt="Wedding Photographers near me"
            />
          </a>
        </div>
      )}
    </section>
  );
};

CTA.defaultProps = {
  showExpertiseIcon: true,
};

export default CTA;
