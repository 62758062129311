import React, { useState, useRef } from "react";
import "scroll-behavior-polyfill";

import V2Entry from "../../v2-entry";
import Footer from "../../footer/Footer";
import FAQSection from "../../../commons/package-template/faq-section";
import PortfolioSection from "../../packages/portfolio-section";
import WhyHireSection from "../../../commons/package-template/why-hire-section";
import ChoosePackageSection from "../../../commons/package-template/choose-packages-section";
import HowWorksSection from "../../../commons/package-template/how-it-works";
import SelectedLocationSection from "../../../commons/package-template/selected-location-section";
import LandingSection from "../../packages/landing-section";
import GetInTouch from "../../homepage/get-in-touch";
import TestimonialSection from "../../packages/testimonial-section";
import AFGCHeader from "../../afgc-header/AFGCHeader";
import MenuOverlay from "../../menu-overlay/MenuOverlay";
import SEO from "../../../commons/seo";
import { Package } from "../../../../models/package.model";
import { FAQ } from "../../../../models/faq.model";
import { Testimonial } from "../../../../models/testimonial.model";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { getPackagesLink } from "../../../../utils/link.helper";
import { Video } from "../../../../models/video";
import CTA from "../../packages/cta";
import { Slugs } from "../../../../enums/slugs.enum";

const Packages = (props: any) => {
  const [showHeader, setShowHeader] = useState(false);
  const getInTouchRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const {
    portfolio_slug,
    _package,
    all_packages: allPackages,
    imagesMeta: currentPortfolioImages,
    video: currentPortfolioVideos,
  }: {
    faq_slug: string;
    portfolio_slug: string;
    video: Video[];
    _package: Package;
    all_packages: {
      location: string;
      location_slug: string;
      cover_image: string;
      cover_image_thumb: string;
    }[];
    imagesMeta: {
      url: string;
      caption: string;
      link: string;
    }[];
  } = props.pageContext;

  const rawFaqs: GqlListResponse<{ frontmatter: FAQ }> = props.data.faqs;
  const faqs = rawFaqs.edges.map(f => f.node.frontmatter);

  const allFaqs = [...(_package.faqs || []), ...(faqs || [])];

  const rawTestimonials: GqlListResponse<Testimonial> = props.data.testimonials;
  const testimonials = rawTestimonials.edges.map(t => t.node);

  const {
    metaTitle,
    metaDescription,
    metaKeywordList,
    metaImageName,
  } = _package.seo;

  // Package Section
  const [selectedPackage, chooseSelectedPackage] = useState<string>();
  const selectPackage = (title: string) => {
    chooseSelectedPackage(title);
    scrollToForm();
    setShowForm(true);
  };

  const scrollToForm = () => {
    const getInTouch = getInTouchRef.current;
    getInTouch!.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (event: any) => {
    const howItWorks = howItWorksRef.current;
    const { top } = howItWorks!.getBoundingClientRect();

    if (!showHeader && top < 0) {
      setShowHeader(true);
    }
    if (showHeader && top > 0) {
      setShowHeader(false);
    }
  };

  return (
    <V2Entry handleScroll={handleScroll}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywordList}
        imageUrl={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        pageUrl={getPackagesLink(portfolio_slug, _package.location_slug)}
      />
      {showHeader && <AFGCHeader clicked={() => setIsPopUpOpen(true)} />}
      <main className="column" data-test-e2e="packages-container">
        <LandingSection
          coverImage={_package.cover_image}
          title={_package.page_title}
          subtitle={_package.page_subtitle}
          allPackagesLength={allPackages.length}
          onClick={() => scrollToForm()}
          setIsPopUpOpen={setIsPopUpOpen}
        />
        <SelectedLocationSection
          portfolioSlug={portfolio_slug}
          locationSlug={_package.location_slug}
          package={_package}
          allPackages={allPackages}
        />
        <HowWorksSection
          reference={howItWorksRef}
          title={_package.how_it_works.section_title}
          steps={_package.how_it_works.steps}
        />
        <ChoosePackageSection
          title={_package.packages.section_title}
          subtitle={_package.packages.subtitle}
          options={_package.packages.options}
          selectPackage={selectPackage}
          locationSlug={_package.location_slug}
        />
        <PortfolioSection
          portfolioSlug={portfolio_slug}
          currentPortfolioImages={currentPortfolioImages}
          currentPortfolioVideos={currentPortfolioVideos}
        />
        <WhyHireSection
          sectionTitle={_package.why_hire_me.section_title}
          hireMe={_package.why_hire_me.hire_me}
        />
        <FAQSection allFaqs={allFaqs} />
        <TestimonialSection
          testimonials={testimonials}
          title={_package.testimonials.section_title}
        />
        <CTA
          title={_package.footer_line_title}
          lines={_package.footer_lines || []}
          showExpertiseIcon={portfolio_slug.includes(Slugs.PORTFOLIO_WEDDING)}
        />
        <GetInTouch
          reference={getInTouchRef}
          selectedPackage={selectedPackage}
          showForm={showForm}
        />
      </main>
      <Footer />
      <MenuOverlay
        closePopUp={() => setIsPopUpOpen(false)}
        isOpen={isPopUpOpen}
      />
    </V2Entry>
  );
};

export default Packages;
