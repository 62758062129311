import React from "react";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import { IconSizes } from "../../../../enums/icon-sizes";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import styles from "./shared.module.scss";
import cx from "classnames";

export const leftArrowComponent = (
  goToPrevious: () => void,
  className?: string
) => (
  <SVGContainer
    iconSize={IconSizes.IC__35}
    onClick={goToPrevious}
    className={cx(
      styles.arrow,
      styles.arrow__left,
      styles.arrow__normal,
      className ?? ""
    )}
  >
    <IoIosArrowBack />
  </SVGContainer>
);

export const rightArrowComponent = (
  goToNext: () => void,
  className?: string
) => (
  <SVGContainer
    iconSize={IconSizes.IC__35}
    onClick={goToNext}
    className={cx(
      styles.arrow,
      styles.arrow__right,
      styles.arrow__normal,
      className ?? ""
    )}
  >
    <IoIosArrowForward />
  </SVGContainer>
);
