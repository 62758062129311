import React, { RefObject } from "react";
import styles from "./HowItWorks.module.scss";
import cx from "classnames";
import Slider from "infinite-react-carousel";
import Step from "./step/Step";

interface Props {
  title: string;
  steps: {
    title: string;
    description: string;
  }[];
  reference?: RefObject<HTMLDivElement>;
}

const carouselSettings = {
  arrows: false,
  arrowsBlock: false,
  dots: true,
  adaptiveHeight: true,
};

const HowWorks: React.FC<Props> = (props: Props) => {
  const howWorksCard = (title: string, desc: string, index: number) => {
    return (
      <Step
        key={index}
        number={`0${(index + 1).toString()}`}
        heading={title}
        para={desc}
      />
    );
  };

  return (
    <section className={cx("column", styles.howWorks)} ref={props.reference}>
      <h3>How it works?</h3>
      <p className={cx(styles.desc, "open p3")}>{props.title}</p>
      <section className={styles.howWorks__desktop}>
        {props.steps.map(({ title, description }, index) => {
          return howWorksCard(title, description, index);
        })}
      </section>
      <section className={styles.howWorks__mobile}>
        <Slider {...carouselSettings}>
          {props.steps.map(({ title, description }, index) => {
            return howWorksCard(title, description, index);
          })}
        </Slider>
      </section>
    </section>
  );
};

export default HowWorks;
