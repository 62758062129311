import React from "react";
import styles from "./WhyHireSection.module.scss";
import cx from "classnames";
import CameraIcon from "../../../../images/icons/camera.inline.svg";
import ApertureIcon from "../../../../images/icons/aperture.inline.svg";
import CheckSquareIcon from "../../../../images/icons/check-square.inline.svg";
import WhyHireCard from "./why-hire-card/WhyHireCard";

interface Props {
  sectionTitle: string[];
  hireMe: {
    title: string;
    description: string;
  }[];
}

const WhyHire: React.FC<Props> = (props: Props) => {
  const icons = [
    { icon: <CheckSquareIcon /> },
    { icon: <ApertureIcon /> },
    { icon: <CameraIcon /> },
  ];

  return (
    <section className={styles.container}>
      <div className={cx("column", styles.whyHire)}>
        <h3>Why hire me?</h3>
        <div className={cx("open", styles.whyHire__desc)}>
          {props.sectionTitle.map((desc, index) => (
            <p key={index}>{desc}</p>
          ))}
        </div>
        <section className={cx("column", styles.whyHire__row)}>
          {props.hireMe.map((obj, index) => (
            <WhyHireCard
              key={index}
              children={icons[index].icon}
              heading={obj.title}
              desc={obj.description}
            />
          ))}
        </section>
      </div>
    </section>
  );
};

export default WhyHire;
