import React from "react";
import styles from "./FAQSection.module.scss";
import cx from "classnames";
import { RouteLinks } from "../../../../utils/route-links";
import RightArrowButton from "../../curve-arrow-button/RightArrowButton";
import { FAQ } from "../../../../models/faq.model";
import FAQGroup from "../../faq-group/FAQGroup";

interface Props {
  allFaqs: FAQ[];
}

const Faq: React.FC<Props> = (props: Props) => {
  return (
    <section className={cx("column", styles.faq)}>
      <div className={styles.faq__heading}>
        <h3>Frequently asked questions</h3>
        <RightArrowButton
          text="See all FAQs"
          className={cx("open", styles.faq__btn, styles.desktop)}
          route={RouteLinks.faqs}
        />
      </div>
      <FAQGroup faqs={props.allFaqs} />
      <div className={cx(styles.mobile)}>
        <RightArrowButton
          text="See all FAQs"
          className={cx("open", styles.faq__btn)}
          route={RouteLinks.faqs}
        />
      </div>
    </section>
  );
};

export default Faq;
