import React from "react";
import styles from "./FooterList.module.scss";
import { Link } from "gatsby";
import cx from "classnames";
import { IconSizes } from "../../../../../enums/icon-sizes";
import { SlideDown } from "react-slidedown";
import ChevDownArrow from "../../../../../images/icons/chevron-down.inline.svg";
import ChevUpArrow from "../../../../../images/icons/chevron-up.inline.svg";
import SVGContainer from "../../../../commons/svg-container/SVGContainer";

interface Props {
  heading: string;
  textList: string[];
  linkArray: string[];
  opened: boolean;
  handleClick: Function;
}

const FooterList: React.FC<Props> = (props: Props) => {
  return (
    <ul
      className={cx(
        "open",
        "column",
        styles.container,
        props.opened && styles.opened
      )}
      onClick={() => props.handleClick()}
    >
      <li className={styles.heading}>
        <p>{props.heading}</p>
        <SVGContainer
          className={cx(
            styles.arrow,
            props.opened ? styles.arrow__up : styles.arrow__down
          )}
          iconSize={IconSizes.IC__packagesFooterArrow}
        >
          {props.opened ? <ChevUpArrow /> : <ChevDownArrow />}
        </SVGContainer>
      </li>
      <SlideDown>
        {props.opened
          ? props.textList.map((el, index) => (
              <li key={index} className={styles.listItem}>
                {props.linkArray[index].startsWith("/") && (
                  <Link to={props.linkArray[index]} className="link">
                    {el}
                  </Link>
                )}
                {!props.linkArray[index].startsWith("/") && (
                  <a
                    href={props.linkArray[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {el}
                  </a>
                )}
              </li>
            ))
          : null}
      </SlideDown>
    </ul>
  );
};

export default FooterList;
