import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import styles from "./LandingImageCard.module.scss";
import LocationIcon from "../../../../../images/icons/location-icon.inline.svg";
import { IconSizes } from "../../../../../enums/icon-sizes";
import SVGContainer from "../../../svg-container/SVGContainer";

interface Props {
  location: string;
  location_url: string;
  coverImage: string;
  isSelected?: boolean;
}

const LandingImageCard: React.FC<Props> = (props: Props) => {
  return (
    <Link
      className={styles.LandingImageCard}
      style={{
        backgroundImage: `url(${props.coverImage})`,
      }}
      to={props.location_url}
    >
      <div className={styles.title}>
        <SVGContainer iconSize={IconSizes.IC__packagesTouch}>
          <LocationIcon />
        </SVGContainer>
        <p className={cx("open", props.isSelected && styles.selected)}>
          {props.location}
        </p>
      </div>
    </Link>
  );
};

export default LandingImageCard;
