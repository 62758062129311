import React from "react";
import styles from "./SelectedLocation.module.scss";
import cx from "classnames";
import { Package } from "../../../../models/package.model";
import LandingImageCard from "./landing-image-card/LandingImageCard";
import { getPackagesLink } from "../../../../utils/link.helper";
import { useStaticQuery, graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import { smallScreenQuery, filterPackage } from "../../../../utils/utils";
import {S3Directory} from "../../../../enums/s3-directory.enum";

interface Props {
  package: Package;
  allPackages: {
    location: string;
    location_slug: string;
    cover_image: string;
    cover_image_thumb: string;
  }[];
  portfolioSlug: string;
  locationSlug: string;
}

const query = graphql`
  {
    metadata: site {
      siteMetadata {
        cloudfront_base
      }
    }
  }
`;

const SelectedLocation: React.FC<Props> = (props: Props) => {
  const THUMB_DESKTOP_DIRECTORY = `/${S3Directory.PACKAGE_IMAGES_THUMB}`;
  const THUMB_MOBILE_DIRECTORY = `/${S3Directory.PACKAGE_IMAGES_MOBILE_THUMB}`;
  let thumbDirectory = THUMB_DESKTOP_DIRECTORY;

  const mobileDevice = useMediaQuery({ query: smallScreenQuery });

  if (mobileDevice) {
    thumbDirectory = THUMB_MOBILE_DIRECTORY;
  }

  const data = useStaticQuery(query);
  const cloudfrontBaseUrl: string = data.metadata.siteMetadata.cloudfront_base;

  const allFilteredPackages = filterPackage<{
    location: string;
    location_slug: string;
    cover_image: string;
    cover_image_thumb: string;
  }>(props.package, props.allPackages);

  return (
    <section className={styles.container}>
      <div className={cx("column", styles.selectedState)}>
        {props.allPackages.length > 2 &&
          allFilteredPackages.map(p => {
            const imageUrl = p.cover_image_thumb || p.cover_image;
            return (
              <LandingImageCard
                key={p.location_slug}
                location={p.location}
                location_url={getPackagesLink(
                  props.portfolioSlug,
                  p.location_slug
                )}
                coverImage={`${cloudfrontBaseUrl}${thumbDirectory}${imageUrl}`}
                isSelected={p.location_slug === props.locationSlug}
              />
            );
          })}
      </div>
    </section>
  );
};

export default SelectedLocation;
