import React from "react";
import styles from "./Step.module.scss";
import cx from "classnames";

interface Props {
  number: string;
  heading: string;
  para: string;
}

const Step: React.FC<Props> = (props: Props) => {
  return (
    <div className={cx(styles.Step, "column")}>
      <section className={styles.Step__indicator}>
        <div className={cx(styles.Step__content, styles.leftContent)} />
        <div className={styles.circle} />
        <div className={cx(styles.Step__content, styles.rightContent)} />
      </section>
      <section className={styles.Step__main}>
        <h2>{props.number}</h2>
        <h6 className="open">{props.heading}</h6>
        <p className="open">{props.para}</p>
      </section>
    </div>
  );
};

export default Step;