import React from "react";
import styles from "./Testimonial.module.scss";
import { Testimonial } from "../../../../models/testimonial.model";
import cx from "classnames";
import Testimonials from "../../testimonial";

interface Props {
  testimonials: Testimonial[];
  title: string;
}

const TestimonialSection: React.FC<Props> = (props: Props) => {
  const { testimonials, title } = props;

  if (testimonials.length === 0) {
    return null;
  }

  return (
    <section className={styles.backgroundContainer}>
      <div className={cx("column", styles.couplesSay)}>
        <h3>{title}</h3>
        <Testimonials testimonials={testimonials} />
      </div>
    </section>
  );
};

export default TestimonialSection;
