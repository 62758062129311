import React from "react";
import styles from "./WhyHireCard.module.scss";
import { IconSizes } from "../../../../../enums/icon-sizes";
import cx from "classnames";
import SVGContainer from "../../../svg-container/SVGContainer";

interface Props {
  children: React.ReactNode;
  heading: string;
  desc: string;
}

const WhyHireCard: React.FC<Props> = (props: Props) => {
  return (
    <div className={cx("column", styles.WhyHireCard)}>
      <SVGContainer iconSize={IconSizes.IC__packagesWhyHire}>
        {props.children}
      </SVGContainer>
      <h6 className="open">{props.heading}</h6>
      <p className="open">{props.desc}</p>
    </div>
  );
};

export default WhyHireCard;
