import React, { useState } from "react";
import styles from "./PackagesFooter.module.scss";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import { graphql, useStaticQuery } from "gatsby";
import { smallScreenQuery } from "../../../../utils/utils";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { Portfolio } from "../../../../models/portfolio.model";
import moment from "moment";
import LogoIcon from "../../../../images/icons/v2/logo.inline.svg";
import AFGCLogo from "../../../commons/afgc-logo/AFGCLogo";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import AFGCAnchor from "../../../commons/afgc-anchor/AFGCAnchor";
import { IconSizes } from "../../../../enums/icon-sizes";
import { ContactLinks } from "../../../../utils/contact-links";
import { SocialLinks } from "../../../../utils/social-links";
import InstagramIcon from "../../../../images/icons/instagram.inline.svg";
import TwitterIcon from "../../../../images/icons/twitter.inline.svg";
import PinterestIcon from "../../../../images/icons/pinterest.inline.svg";
import FacebookIcon from "../../../../images/icons/v1/facebook.inline.svg";
import FooterList from "./footer-list";
import { RouteLinks } from "../../../../utils/route-links";
import { getFooterPackagesLink } from "../../../../utils/link.helper";
import StudioNinja from "../../../commons/StudioNinja";
import Calendly from "../../../commons/calendly/Calendly";
import EmailIcon from "../../../../images/icons/mail.inline.svg";
import PhoneIcon from "../../../../images/icons/phone.inline.svg";
import CalendlyIcon from "../../../../images/icons/calendly.inline.svg";

interface Props {
  setSubmitPressed: Function;
  selectedPackage?: string | undefined;
  location_slug?: string;
  portfolio_slug?: string;
  footerLastSectionClass?: string;
}

const query = graphql`
  {
    packages: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolios" } } }
      sort: { order: ASC, fields: [fields___relativePath] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            packages {
              location_slug
            }
          }
        }
      }
    }
  }
`;

const PackagesFooter: React.FC<Props> = (props: Props) => {
  const { selectedPackage, location_slug, setSubmitPressed } = props;

  // Footer list opening
  const [openedIndex, setOpenedIndex] = useState(0);

  const matches = useMediaQuery({ query: smallScreenQuery });

  const queryResult = useStaticQuery(query);

  const rawPortfolios: GqlListResponse<Portfolio> = queryResult.packages;
  const portfolios = rawPortfolios.edges.map(t => t.node);

  const filterPorfolios = portfolios.filter(p => !!p.frontmatter.packages);

  return (
    <footer className={styles.container}>
      <section className={cx("column", styles.content)}>
        <section className={cx("column", styles.getInTouch)}>
          {!!selectedPackage && (
            <section className={cx("d5", styles.getInTouch__selectedPackage)}>
              Package&nbsp;:&nbsp;<span>{selectedPackage}</span>
            </section>
          )}
          <div className={cx("column", styles.getInTouch__main)}>
            <section className={styles.left}>
              <div className={styles.leftContent}>
                <h3>Let’s get in touch</h3>
                <p className={cx("open", styles.leftContent__desc)}>
                  Please fill out the contact form below and I'll be in touch
                  shortly
                </p>
                <div className={styles.leftContent__divider} />
                <div className={cx("open", styles.leftContent__iconRow)}>
                  <SVGContainer iconSize={IconSizes.IC__packagesTouch}>
                    <EmailIcon />
                  </SVGContainer>
                  <AFGCAnchor
                    ariaLabel="Mail Link"
                    url={`mailto:${ContactLinks.mail}`}
                  >
                    {ContactLinks.mail}
                  </AFGCAnchor>
                </div>
                <div className={cx("open", styles.leftContent__iconRow)}>
                  <SVGContainer iconSize={IconSizes.IC__packagesTouch}>
                    <PhoneIcon />
                  </SVGContainer>
                  <AFGCAnchor
                    ariaLabel="Phone Link"
                    url={`tel:${ContactLinks.phone}`}
                  >
                    {ContactLinks.phoneDisplay}
                  </AFGCAnchor>
                </div>
                <div className={cx("open", styles.leftContent__iconRow)}>
                  <SVGContainer iconSize={IconSizes.IC__packagesTouch}>
                    <CalendlyIcon />
                  </SVGContainer>
                  <Calendly text="Book via Calendly" />
                </div>
              </div>
            </section>
            <section className={styles.right}>
              <StudioNinja />
            </section>
          </div>
        </section>
        <section className={cx("column", styles.footer)}>
          <section className={cx("column", styles.footer__left)}>
            <AFGCLogo className={styles.footer__logo}>
              <LogoIcon />
            </AFGCLogo>
            <p className="open">
              All the images used in this website are the property of{" "}
              <span>a few good clicks</span> and used with permission.
            </p>
            <div className={styles.footer__iconRow}>
              <AFGCAnchor
                ariaLabel="Share on Instagram"
                url={SocialLinks.instagram}
              >
                <SVGContainer iconSize={IconSizes.IC__packagesFooter}>
                  <InstagramIcon />
                </SVGContainer>
              </AFGCAnchor>
              <AFGCAnchor
                ariaLabel="Share on Pinterest"
                url={SocialLinks.pinterest}
              >
                <SVGContainer iconSize={IconSizes.IC__packagesFooter}>
                  <PinterestIcon />
                </SVGContainer>
              </AFGCAnchor>
              <AFGCAnchor
                ariaLabel="Share on Twitter"
                url={SocialLinks.twitter}
              >
                <SVGContainer iconSize={IconSizes.IC__packagesFooter}>
                  <TwitterIcon />
                </SVGContainer>
              </AFGCAnchor>
              <AFGCAnchor
                ariaLabel="Share on Facebook"
                url={SocialLinks.facebook}
              >
                <SVGContainer iconSize={IconSizes.IC__packagesFooter}>
                  <FacebookIcon />
                </SVGContainer>
              </AFGCAnchor>
            </div>
          </section>
          <section className={cx("column", styles.footer__right)}>
            <FooterList
              heading="Navigation"
              textList={[
                "Portfolio",
                "Testimonials",
                "Blog",
                "Who am I",
                "Contact me",
              ]}
              linkArray={[
                RouteLinks.portfolio,
                RouteLinks.testimonial,
                RouteLinks.blogs,
                RouteLinks.whoAmI,
                RouteLinks.contact,
              ]}
              opened={matches ? openedIndex === 0 : true}
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(0);
                }
              }}
            />
            <FooterList
              heading="Packages"
              textList={filterPorfolios.map(p => p.frontmatter.title)}
              linkArray={filterPorfolios.map(p => getFooterPackagesLink(p))}
              opened={matches ? openedIndex === 1 : true}
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(1);
                }
              }}
            />
            <FooterList
              heading="Help"
              textList={["COVID-19", "Privacy Policy", "Cookie Policy", "Terms of use"]}
              linkArray={[RouteLinks.covid, RouteLinks.privacy, RouteLinks.cookie, RouteLinks.terms]}
              opened={matches ? openedIndex === 2 : true}
              handleClick={() => {
                if (matches) {
                  setOpenedIndex(2);
                }
              }}
            />
          </section>
        </section>
      </section>
      <section
        className={cx(
          "open column",
          styles.lastSection,
          props.footerLastSectionClass ?? ""
        )}
      >
        <span>a few good clicks - {moment().year()}</span>
      </section>
    </footer>
  );
};

export default PackagesFooter;
