import React from "react";
import styles from "./PackagesCard.module.scss";
import cx from "classnames";
import AFGCButton from "../../../../v2/afgc-button/AFGCButton";
import { BsCheckCircle } from "react-icons/bs";
import SVGContainer from "../../../svg-container/SVGContainer";
import { IconSizes } from "../../../../../enums/icon-sizes";

interface Props {
  name: string;
  price: string;
  desc: string[];
  choose: Function;
}

const Package: React.FC<Props> = (props: Props) => {
  const price = props.price.trim();
  let text, updatedPrice;
  if (price.toLowerCase().includes("starts")) {
    const str = price.split(" ");
    text = str[0] + " " + str[1];
    updatedPrice = str[2];
  } else {
    updatedPrice = price;
  }

  return (
    <div className={cx("column", styles.Package)}>
      <h6 className="open">{props.name}</h6>
      <div className={styles.Package__price}>
        {text && <p className="open">{text}</p>}
        <h2>{updatedPrice}</h2>
      </div>
      <ul className="open">
        {props.desc.map((el, index) => (
          <li className={styles.para} key={index}>
            <SVGContainer iconSize={IconSizes.IC__16}>
              <BsCheckCircle />
            </SVGContainer>
            <p>{el}</p>
          </li>
        ))}
      </ul>
      <AFGCButton className="open" onClick={props.choose}>
        Choose
      </AFGCButton>
    </div>
  );
};

export default Package;
