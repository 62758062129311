import React from "react";
import styles from "./LandingSection.module.scss";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import { smallScreenQuery } from "../../../../utils/utils";
import AFGCButton from "../../afgc-button/AFGCButton";
import AFGCHeader from "../../afgc-header/AFGCHeader";
import { HeaderType } from "../../../../enums/header-type.enum";
import {S3Directory} from "../../../../enums/s3-directory.enum";

interface Props {
  allPackagesLength: number;
  title: string;
  subtitle: string;
  coverImage: string;
  onClick: () => void;
  setIsPopUpOpen: Function;
}

const query = graphql`
  {
    metadata: site {
      siteMetadata {
        cloudfront_base
      }
    }
  }
`;

const LandingSection: React.FC<Props> = (props: Props) => {
  const COVER_DESKTOP_DIRECTORY = `/${S3Directory.PACKAGE_IMAGES}`;
  const COVER_MOBILE_DIRECTORY = `/${S3Directory.PACKAGE_IMAGES_MOBILE}`;

  let coverDirectory = COVER_DESKTOP_DIRECTORY;

  const mobileDevice = useMediaQuery({ query: smallScreenQuery });

  if (mobileDevice) {
    coverDirectory = COVER_MOBILE_DIRECTORY;
  }

  const data = useStaticQuery(query);
  const cloudfrontBaseUrl: string = data.metadata.siteMetadata.cloudfront_base;

  const { setIsPopUpOpen } = props;

  return (
    <section className={styles.backgroundContainer}>
      <div
        className={cx(
          styles.landingImage,
          props.allPackagesLength <= 2 && styles.fullLandingImage,
        )}
        style={{
          backgroundImage: `url(${cloudfrontBaseUrl}${coverDirectory}${props.coverImage})`,
        }}
      >
        <div className={cx("column", styles.overlay)}>
          <AFGCHeader
            clicked={() => setIsPopUpOpen(true)}
            headerType={HeaderType.WHITE}
          />
          <div className={styles.divider} />
          <section className={cx("column", styles.metaContainer)}>
            <div className={styles.meta}>
              <h1>{props.title}</h1>
              <p className={cx("open", styles.meta__centerText)}>
                {props.subtitle}
              </p>
              <AFGCButton
                className={cx("open", styles.meta__btn)}
                onClick={props.onClick}
              >
                Let’s get in touch
              </AFGCButton>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
